import React from "react";
import { Container, Divider, Grid, Header } from "semantic-ui-react";
// import {Link} from "react-router-dom";
import "./Home.scss"

export default class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <section className="hero transparent top-wrap">
          <span className="hero-wave-top" />
          {/* <span className="hero-wave" /> */}
          <Container>
            <div className="top-wrap-left">
              <Header size="huge">外卖客的专属小程序</Header>
              <Header size="medium">我们致力于打造完善的行业解决方案，为您提供全方位一站式服务，助力客户成功。通过使用我们的软件，帮助您的业务快速增长。</Header>
              <div className="btns">
                <a href={"#section-contact"} className="large ui button primary btn-contact"><i className="icon comment" /> 立即咨询</a>
                {/* <Link to={"/cooperation"} className="large ui button green"><i className="icon handshake"/> 交流合作</Link> */}
              </div>
            </div>
            <img className="banner" src="https://cdn.0g1s.com/20200826/FjlNsLEaWqrRuEof0MCehkIoX-JJ.svg" alt="banner" />
          </Container>
          <div className="banner-bg"></div>
        </section>
        <section className="hero gray">
          <Container>
            <Divider horizontal>热门服务不要错过！</Divider>
            <Grid stackable columns={4}>
              <Grid.Row columns={4}>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconkanjia" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">外卖会员卡砍价</div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconqiandao" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">每日打卡领会员</div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconqianghongbao" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">口令裂变红包</div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconminiprogram" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">卡券小程序</div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </section>
        <section className="hero transparent">
          <Container textAlign="center">
            <Divider horizontal>更多玩法正在开发中，尽情期待！</Divider>
            <div className="content">
              <Header size="huge">专为外卖客定制的裂变系统</Header>
              <Header size="large">让您的红包转化率更上一层楼！</Header>
            </div>
            {/* <Divider horizontal>产品案例</Divider> */}
            <Grid stackable columns="2">
              <Grid.Column>
                <div className="qrcode">
                  <img src='https://wm.0g1s.com/1_v1.jpeg' alt="" width="300" />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="qrcode">
                  <img src='https://wm.0g1s.com/2_v1.jpeg' alt="" width="300" />
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </section>

        <section className="hero gray" id="section-contact">
          <Container textAlign="center">
            <Divider horizontal>联系我们</Divider>
            <Grid stackable columns="1">
              <Grid.Column>
                <div className="qrcode">
                  {/* <div className="title">对接咨询</div> */}
                  <img src="https://wm.0g1s.com/contact1.png" alt="kefu" height="200" />
                  <div className="info">添加好友请填写验证消息</div>
                  <div className="keyword">对接外卖之光</div>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </section>

        <section className="hero">
          <Container textAlign="center">
            <Divider horizontal>功能演示</Divider>
            <Grid stackable columns="1">
              <Grid.Column>
                <div className="qrcode">
                  <div className="title">演示小程序</div>
                  <img src="https://wm.0g1s.com/gh_ae450981339c_258.jpg" alt="" height="200" />
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </section>
      </div>
    )
  }
}
