import React from "react";
import { Container, Menu } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import "./Header.scss";
import logo from "../assets/text-logo.svg";

export default function Header(props) {

  const history = useHistory();

  return (
    <header>
      <Container className="pc">
        <Menu secondary size='large'>
          <Menu.Item name='home' onClick={() => {
            history.push("/");
          }}>
            <img
              className="ui logo"
              src={logo}
              alt="logo"
            />
          </Menu.Item>
          {/* <Menu.Menu size='large' position="right">
            <Menu.Item name='changelog' onClick={() => {
              history.push("/changelog");
            }}>更新日志</Menu.Item>
          </Menu.Menu> */}
        </Menu>
      </Container>
      <Container className="mobile">
        <Menu secondary>
          <Menu.Item name='home' onClick={() => {
            history.push("/");
          }}>
            <img
              className="ui logo"
              src={logo}
              alt="logo"
            />
          </Menu.Item>
          {/* <Menu.Menu size='large' position="right">
            <Menu.Item name='changelog' onClick={() => {
              history.push("/changelog");
            }}>更新日志</Menu.Item>
            <Menu.Item position="right" name='login' onClick={() => {
              window.open('https://console.aiioii.com')
            }}>登录系统</Menu.Item>
          </Menu.Menu> */}
        </Menu>
      </Container>
    </header>
  );
}
